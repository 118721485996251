.tiles-list {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-md) {
		flex-direction: row;
		justify-content: space-between;
	}

	@media (min-width: $screen-lg) {
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: 16px;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 32px;
	}

	&__item {

		& + #{$self}__item {
			margin-top: 16px;
		}

		@media (min-width: $screen-md) {
			width: 48.5%;

			& + #{$self}__item {
				margin: 0;
			}
		}

		@media (min-width: $screen-lg) {
			width: 100%;

			& + #{$self}__item {
				margin-top: 16px;
			}
		}

		@media (min-width: $screen-xl) {

			& + #{$self}__item {
				margin-top: 32px;
			}
		}
	}

	&__link {
		display: block;
		position: relative;
		width: 100%;
		padding: 14px 16px;
		overflow: hidden;

		:hover {
			#{$self}__title {

				border-bottom: 1px solid transparent;
			}
		}

		&--theme_light {
			background-color: $blue-light;

			&:hover {
				background-color: $blue-light--hover;
			}
		}

		&--theme_lighten {
			background-color: $blue-lighten;

			&:hover {
				background-color: $blue-lighten--hover;
			}
		}

		@media (min-width: $screen-md) {

			height: 225px;
		}

		@media (min-width: $screen-lg) {

		}

		@media (min-width: $screen-xl) {
			height: 320px;
			padding: 48px 32px;
		}

		@media (min-width: $screen-xxl) {
			height: 370px;
		}
	}

	&__title {
		font-size: 24px;
		line-height: 36px;
		color: $white;
		border-bottom: 1px solid rgba(255, 255, 255, 0.25);

		&:hover {
			border-bottom: 1px solid transparent;
		}

		@media (min-width: $screen-md) {
			margin-bottom: 24px;
		}

		@media (min-width: $screen-xl) {
			font-size: 36px;
		}
	}

	&__img-wrap {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
			position: absolute;
			bottom: -12px;
			max-width: 270px;
			height: 166px;
		}

		@media (min-width: $screen-xl) {
			bottom: -13px;
			max-width: 365px;
			height: auto;
		}

		@media (min-width: $screen-xxl) {
			max-width: 428px;
		}
	}

	&__img {
		max-height: 100%;
	}
}
