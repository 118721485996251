@mixin triangle-arrow(
	$triangle-size,
  $triangle-thickness,
  $triangle-color,
  $triangle-direction,
  $translate-x,
  $translate-y
) {
	width: $triangle-size;
	height: $triangle-size;
	border: $triangle-thickness solid;
	border-color: $triangle-color $triangle-color transparent transparent;

	@if ($triangle-direction == down) {
		transform: translateY($translate-y) translateX($translate-x) rotate(135deg);
	}
	@if ($triangle-direction == up) {
		transform: translateY($translate-y) translateX($translate-x) rotate(-45deg);
	}
	@if ($triangle-direction == right) {
		transform: translateY($translate-y) translateX($translate-x) rotate(45deg);
	}
	@if ($triangle-direction == left) {
		transform: translateY($translate-y) translateX($translate-x) rotate(-135deg);
	}
}

@mixin visuallyhidden() {
	position: absolute;
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}


