.banner {
	$self: &;
	position: relative;
	max-width: 1920px;
	height: 232px;
	margin: 0 auto;
	background-image: url("../img/image-banner-xl.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;

	&__video-wrap {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1024px;
			height: 576px;
			transform: translate(-50%, -50%);

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.25);
				background-image: url("../img/pattern-grid.png");
				background-repeat: repeat;
			}
		}


		@media (min-width: $screen-md) {
			width: 1024px;
			height: 576px;
		}

		@media (min-width: $screen-lg) {
			width: 1280px;
			height: 720px;
		}

		@media (min-width: $screen-xl) {
			width: 1920px;
			height: 1080px;
		}
	}

	&__video {
		width: 100%;
		height: 100%;
	}

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 50%;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
	}

	@media (min-width: $screen-lg) {
		height: 540px;
	}

	&--inner {

		#{$self}__content {

			@media (min-width: $screen-lg) {
				padding-bottom: 28px;
			}

			@media (min-width: $screen-xl) {
				padding-bottom: 48px;
			}
		}

		@media (min-width: $screen-lg) {
			height: 440px;
		}
	}

	&--video {

		@media (min-width: $screen-lg) {
			height: 440px;

			&::after {
				content: none;
			}
		}

		@media (min-width: $screen-xl) {
			height: 560px;
		}
	}

	&--image_advantages {
		background-image: url("../img/image-banner--inner-01-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-01-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-01-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-01-xl.jpg");
		}
	}

	&--image_history {
		background-image: url("../img/image-banner--inner-02-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-02-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-02-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-02-xl.jpg");
		}
	}

	&--image_service {
		background-image: url("../img/image-banner--inner-03-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-03-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-03-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-03-xl.jpg");
		}
	}

	&--image_rules {
		background-image: url("../img/image-banner--inner-04-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-04-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-04-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-04-xl.jpg");
		}
	}

	&--image_payment {
		background-image: url("../img/image-banner--inner-05-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-05-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-05-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-05-xl.jpg");
		}
	}

	&--image_contacts {
		background-image: url("../img/image-banner--inner-06-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-06-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-06-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-06-xl.jpg");
		}
	}

	&--image_support {
		background-image: url("../img/image-banner--inner-07-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-07-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-07-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-07-xl.jpg");
		}
	}

	&--image_documents {

		background-image: url("../img/image-banner--inner-08-sm.jpg");

		@media (min-width: $screen-md) {
			background-image: url("../img/image-banner--inner-08-md.jpg");
		}

		@media (min-width: $screen-lg) {
			background-image: url("../img/image-banner--inner-08-lg.jpg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/image-banner--inner-08-xl.jpg");
		}
	}

	&__content {
		@include container(40px, 40px, 40px, 40px, 16px, 16px);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		z-index: 10;
		height: 100%;
		padding-bottom: 20px;

		@media (min-width: $screen-lg) {
			padding-bottom: 48px;
		}

		@media (min-width: $screen-xl) {
			padding-bottom: 78px;
		}
	}

	&__title {
		margin: 0;
		padding: 0;
		font-weight: 500;
		font-size: 24px;
		line-height: 34px;
		color: $white;

		@media (min-width: $screen-md) {
			font-size: 36px;
			line-height: 42px;
		}

		@media (min-width: $screen-lg) {
			font-weight: 300;
			font-size: 64px;
			line-height: 64px;
		}

		@media (min-width: $screen-xl) {
			font-size: 72px;
			line-height: 72px;
		}
	}
}
