.btn {
	display: inline-block;
	padding: 16px 16px;
	font-size: 20px;
	line-height: 28px;
	color: $color-main;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border: 2px solid $color-main;
	cursor: pointer;
	user-select: none;

	&:hover {
		color: $white;
		background-color: $color-main;
	}

	&:disabled {
		opacity: 0.3;
		pointer-events: none;
		cursor: default;
	}

	&__icon {
		margin-right: 16px;
	}

	&--style_filled {
		color: $white;
		background-color: $color-main;

		&:hover {
			color: $white;
			background-color: $color-main--hover;
			border-color: $color-main--hover;
		}
	}

	&--size_small {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&--theme_gray {
		color: $grey;
		border-color: $grey;
	}

	&--icon {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 32px;
		font-size: 16px;
		line-height: 24px;

		svg {
			vertical-align: top;

			path {
				fill: $grey;
				transition: all $transition-time;
			}
		}

		&:hover {
			color: $white;
			background-color: $color-main;
			border-color: $color-main;

			svg {

				path {
					fill: $white;
				}
			}
		}
	}
}

.load-more,
.back-btn {
	display: inline-block;
	line-height: 24px;
	color: $grey-light;
	vertical-align: middle;

	.icon {
		display: inline-block;
		vertical-align: middle;
	}

	&:hover {
		color: $grey;

		.icon {
			stroke: $grey;
		}
	}
}

.load-more {
	font-size: 18px;

	&__title {
		display: inline-block;
		vertical-align: middle;
		margin-left: 11px;
	}
}

.back-btn {
	font-size: 16px;

	@media (min-width: $screen-xl) {
		font-size: 18px;
	}

	&__title {
		display: inline-block;
		vertical-align: middle;
		margin-left: 12px;
	}
}
