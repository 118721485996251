.search {

	@media (min-width: $screen-xl) {
		padding-top: 56px;
	}

	&__title {
		margin-top: 0;
		margin-bottom: 24px;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;

		@media (min-width: $screen-xl) {
			margin-bottom: 32px;
			font-size: 36px;
			line-height: 42px;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;

		@media (min-width: $screen-sm) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__field {
		flex-grow: 1;

		@media (max-width: $screen-sm - 1) {
			width: 100%;
			margin-bottom: 16px;
		}
	}

	&__form-input {
		width: 100%;

		@media (min-width: $screen-sm) {
			border-right: none;
		}
	}

	&__btn-wrap {
		flex-shrink: 0;

		@media (max-width: $screen-sm - 1) {
			width: 100%;
		}
	}

	&__btn {
		width: 100%;

		@media (min-width: $screen-md) {
			min-width: 184px;
		}

		@media (min-width: $screen-lg) {
			min-width: 284px;
		}
	}

	&__empty {
		padding-top: 32px;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;

		@media (min-width: $screen-lg) {
			font-size: 24px;
			line-height: 34px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 64px;
		}
	}

	&__results {
		padding-top: 16px;

		@media (min-width: $screen-xl) {
			width: calc(100% - 284px);
			padding-top: 32px;
		}
	}

	&__results-title {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 30px;

		strong {
			font-weight: 700;
		}
	}

	&__list {
		margin: 0;
		padding: 24px 0;
		list-style: none;

		@media (min-width: $screen-xl) {
			padding: 48px 0;
		}
	}

	&__item {
		padding-top: 16px;
		padding-bottom: 24px;
		border-bottom: 1px solid #E0E0E0;

		&:first-child {
			border-top: 1px solid #E0E0E0;
		}

		@media (min-width: $screen-xl) {
			padding-top: 32px;
			padding-bottom: 48px;
		}
	}

	&__item-title {
		margin-top: 0;
		margin-bottom: 12px;
		font-weight: 500;
		font-size: 18px;
		line-height: 30px;

		@media (min-width: $screen-xl) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__item-text {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 24px;
		color: $grey;

		&::before {
			content: "... ";
		}

		&::after {
			content: " ...";
		}

		span {
			font-weight: 700;
		}
	}

	&__item-link {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: $grey-light;

		&:hover {
			color: $red;
		}
	}
}
