.press-info {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin-top: 40px;
	padding: 24px;
	background-color: #F0F0F0;

	@media (min-width: $screen-lg) {
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	@media (min-width: $screen-xl) {
		margin-top: 64px;
		padding: 32px;
	}

	&__text {

		@media (max-width: $screen-lg - 1) {
			margin-bottom: 24px;
			text-align: center;
		}

		@media (min-width: $screen-lg) {
			margin-right: 32px;
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__btn-wrap {
		width: 100%;
	}

	&__btn {
		width: 100%;
		max-width: 284px;

		@media (max-width: $screen-lg - 1) {
			margin: 0 auto;
		}
	}
}
