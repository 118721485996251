.map {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 0;

	@media (min-width: $screen-xl) {
		padding-top: 56px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__img-wrap {
		@include col(md, 6, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
		display: none;

		@media (min-width: $screen-md) {
			display: block;
		}

		@media (min-width: $screen-xl) {
			padding-right: 24px;
		}
	}

	&__img {
		width: 100%;
	}

	&__links {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(lg, 4, 12, 40px);
		@include col(xl, 4, 12, 16px);

		@media (min-width: $screen-xl) {
			padding-left: 24px;
		}
	}

	&__preview {
		width: 200px;
		height: 200px;
		margin-bottom: 24px;
		border-radius: 50%;
		overflow: hidden;

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__preview-img {
		max-width: 100%;
		max-height: 100%;
	}

	&__title {
		margin-top: 0;

		@media (min-width: $screen-xl) {
			margin-bottom: 24px;
		}
	}
}
