.pagination {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 0 -16px 0;
	padding: 0;
	list-style: none;

	&__item {
		flex-shrink: 0;
		margin-bottom: 16px;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}

		&--active {

			#{$self}__link {
				background-color: #E0E0E0;
				pointer-events: none;
			}
		}
	}

	&__link {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		color: $text-color;
		border: 2px solid #E0E0E0;
		border-radius: 50%;

		&:hover {
			border-color: $grey-light;
		}
	}
}
