.section {

	&__container {
		@include container(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__row {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__col {
		@include col(xs, 12, 12, 40px);
		@include col(xl, 12, 12, 16px);
	}

	&--top {

		@media (min-width: $screen-xl) {
			padding-top: 56px;
		}
	}
}
