// DATE PICKER

.ui-datepicker {
	width: auto;
}

.ui-widget.ui-widget-content {
	margin-top: 4px;
	padding: 12px;
	background-color: #F0F0F0;
	border: 2px solid #C8C8C8 !important;
	border-radius: 0;
	box-shadow: none;
	color: $text-color;
}

.ui-datepicker .ui-widget-header {
	width: 100%;
	margin: 0 auto !important;
	padding: 0 !important;
	font-family: $font-family;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-transform: uppercase;
	color: $text-color;
	background: none;
	border: none;
	border-bottom: none;
	border-radius: 0;
}

.ui-datepicker .ui-datepicker-title {
	margin: 0;
	padding-top: 8px;
	padding-bottom: 8px;
	font-family: $font-family;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-transform: none;
	background-color: #E0E0E0;
	user-select: none;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	top: 50%;
	background: transparent;
	border: none;
	border-radius: 0;
	transform: translateY(-50%);
	cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev {
	left: 6px;
}

.ui-datepicker .ui-datepicker-next {
	right: 6px;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
	background: transparent !important;
	border: none;
	border-radius: 0;
}

.ui-datepicker .ui-datepicker-prev-hover {
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.ui-datepicker .ui-datepicker-next-hover {
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	top: 50%;
	left: 50%;
	width: 10px;
	height: 19px;
	margin: 0;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	transform: translate(-50%, -50%);
}

.ui-datepicker .ui-datepicker-prev span {
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='19' viewBox='0 0 10 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L2 9.5L9 18' stroke='%23323232' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.ui-datepicker .ui-datepicker-next span {
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='19' viewBox='0 0 10 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.09961 18L8.09961 9.5L1.09961 1' stroke='%23323232' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.ui-datepicker table {
	margin: 0;
	font-family: $font-family;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
}

.ui-datepicker th {
	padding: 16px 10px 16px 10px;
	font-family: $font-family;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-transform: none;
	color: rgba($text-color, 0.6);
}

.ui-datepicker td {
	padding: 0;
	font-family: $font-family;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-transform: uppercase;
	color: $text-color;
}

.ui-datepicker td span,
.ui-datepicker td a {
	padding: 2px 10px 2px 10px;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	color: $text-color;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	text-align: center;
	background: none;
	border: none;
	font-weight: 500;
	color: $text-color;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	color: $white !important;
	background: rgba($red, 0.6);
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	color: $white;
	background: $red;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
	color: $white;
	background: rgba($red, 0.6);
}
