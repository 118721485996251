.news-item {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);

	&__row {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__return {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 12, 12, 40px);
		@include col(xl, 12, 12, 16px);

		@media (min-width: $screen-lg) {
			padding-top: 24px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 52px;
			padding-top: 32px;
		}
	}

	&__card {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
		margin-bottom: 36px;

		@media (min-width: $screen-lg) {
			margin-bottom: 0;
		}

		@media (min-width: $screen-xl) {
			padding-right: 24px;
		}
	}

	&__list-wrap {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 4, 12, 40px);
		@include col(xl, 4, 12, 16px);

		@media (min-width: $screen-xl) {
			padding-left: 24px;
		}
	}

	&__title {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		margin-bottom: 16px;

		@media (min-width: $screen-xl) {
			margin-bottom: 32px;
		}
	}

	&__img {
		width: 100%;
	}
}

.news-slider {
	margin-bottom: 8px;

	&__item {
		position: relative;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			width: 18%;
			height: 100%;
		}

		&::before {
			left: 0;
			background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) -7.91%, rgba(0, 0, 0, 0) 52.03%);
		}

		&::after {
			right: 0;
			background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.5) -7.91%, rgba(0, 0, 0, 0) 52.03%);
		}
	}
}
