.payment-item {

	&__title {
		margin-top: 32px;
		margin-bottom: 24px;

		@media (min-width: $screen-xl) {
			margin-bottom: 38px;
		}
	}

	&__img-wrap {
		width: 238px;
		margin-bottom: 24px;
		
		@media (min-width: $screen-xl) {
			width: 294px;
			margin-bottom: 56px;
		}
	}

	&__img {
		text-align: left;
	}

	&__text {

	}
}
