.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: 19px;

	@media (min-width: $screen-xl) {
		bottom: 43px;
	}
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	background-color: rgba(255, 255, 255, 0.25);
	opacity: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active {
	background-color: rgba(255, 255, 255, 1);
}

.swiper-button-next,
.swiper-button-prev {
	background: none;

	&:hover {
		border-right-color: $white;
		border-bottom-color: $white;
	}
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	width: 18px;
	height: 18px;
	border-right: 2px solid rgba(255, 255, 255, 0.70);
	border-bottom: 2px solid rgba(255, 255, 255, 0.70);
	transform-origin: top left;
	transform: rotate(-45deg) translate(-50%, -50%);
	border-radius: 0;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	width: 18px;
	height: 18px;
	border-right: 2px solid rgba(255, 255, 255, 0.70);
	border-bottom: 2px solid rgba(255, 255, 255, 0.70);
	transform-origin: top left;
	transform: rotate(135deg) translate(-50%, -50%);
	border-radius: 0;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	border-right: 2px solid rgba(255, 255, 255, 0.35);
	border-bottom: 2px solid rgba(255, 255, 255, 0.35);
	opacity: 1;
}

.swiper-button-next--rounded,
.swiper-button-prev--rounded {
	width: 35px;
	height: 35px;
	background: none;
	border: 2px solid #DCDCDC;
	border-radius: 50%;
	transform: none;

	&:hover {
		border: 2px solid #8D8D8D;
	}
}

.swiper-button-next--rounded,
.swiper-container-rtl .swiper-button-prev--rounded {
	right: 20px;
	top: 50%;
	transform: translateY(-50%);

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 10px;
		width: 15px;
		height: 15px;
		border-right: 2px solid #DCDCDC;
		border-bottom: 2px solid #DCDCDC;
		transform-origin: 0 0;
		transform: rotate(-45deg) translate(-50%, -50%);
		border-radius: 0;
	}

	&:hover {

		&::after {
			border-right: 2px solid #8D8D8D;
			border-bottom: 2px solid #8D8D8D;
		}
	}
}

.swiper-button-prev--rounded,
.swiper-container-rtl .swiper-button-next--rounded {
	left: 20px;
	top: 50%;
	transform: translateY(-50%);

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 20px;
		width: 15px;
		height: 15px;
		border-right: 2px solid #DCDCDC;
		border-bottom: 2px solid #DCDCDC;
		transform-origin: 0 0;
		transform: rotate(135deg) translate(-50%, -50%);
		border-radius: 0;
	}

	&:hover {

		&::after {
			border-right: 2px solid #8D8D8D;
			border-bottom: 2px solid #8D8D8D;
		}
	}
}

.swiper-button-prev--rounded.swiper-button-disabled,
.swiper-button-next--rounded.swiper-button-disabled {
	border: 2px solid rgba(#DCDCDC, 0.3);

	&:hover {
		border: 2px solid rgba(#DCDCDC, 0.3);
	}

	&::after {
		border-right: 2px solid rgba(#DCDCDC, 0.3);
		border-bottom: 2px solid rgba(#DCDCDC, 0.3);
	}
}
