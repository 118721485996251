.news-list {
	$self: &;
	margin: 0;
	padding: 0;
	list-style: none;

	&--reverse {

		#{$self}__wrap-link {
			order: -1;
			margin-bottom: 10px;

			@media (min-width: $screen-xl) {
				margin-bottom: 18px;
			}
		}
	}

	&--compact {

		#{$self}__item + #{$self}__item {
			margin-top: 36px;
		}

		#{$self}__title {
			margin-bottom: 0;
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $screen-xl) {
				font-size: 18px;
				line-height: 30px;
			}
		}

		#{$self}__date {

			@media (min-width: $screen-xl) {
				line-height: 20px;
				margin-bottom: 8px;
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	&__date {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 1;
		color: $grey-light;
	}

	&__title {
		margin: 0;
		margin-bottom: 8px;

		@media (min-width: $screen-xl) {
			margin-bottom: 10px;
		}
	}

	&__text {
		flex-grow: 1;
		margin-bottom: 10px;
		color: $grey;

		@media (min-width: $screen-xl) {
			line-height: 28px;
		}
	}

	&__wrap-link {
		flex-shrink: 0;
		display: block;
	}
}

.main-info__news-list {

	.news-list__item + .news-list__item {
		margin-top: 40px;

		@media (min-width: $screen-xl) {
			margin-top: 50px;
		}
	}

	margin-bottom: 56px;

	@media (min-width: $screen-md) {
		margin-bottom: 64px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: 0;
	}
}

.news__news-list {
	@include row(0, 0, 0, 0, 0, 0);

	.news-list__item {
		@include col(md, 6, 12, 0);
		@include col(lg, 6, 12, 0);
		@include col(xl, 6, 12, 0);

		@media (min-width: $screen-md) {
			margin-bottom: 24px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 32px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 40px;
		}
	}

	.news-list__img-wrap {
		max-width: 335px;

		@media (min-width: $screen-md) {
			max-width: none;
		}
	}

	.news-list__item + .news-list__item {
		margin-top: 32px;
	}

	.news-list__item:nth-child(odd) {

		@media (min-width: $screen-md) {
			padding-right: 10px;
		}

		@media (min-width: $screen-xl) {
			padding-right: 24px;
		}
	}

	.news-list__item:nth-child(even) {

		@media (min-width: $screen-md) {
			padding-left: 10px;
		}

		@media (min-width: $screen-xl) {
			padding-left: 24px;
		}
	}

	@media (min-width: $screen-md) {

		.news-list__item + .news-list__item {
			margin-top: 0;
		}
	}
}
