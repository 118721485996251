.tile-slider {

	height: 225px;
	margin-bottom: 16px;
	background-color: $blue-darken;

	@media (min-width: $screen-xl) {
		height: 320px;
		margin-bottom: 32px;
	}
	
	@media (min-width: $screen-xxl) {
		height: 370px;
	}

	&__item {
		width: 100%;
		padding: 24px 16px;
		box-sizing: border-box;

		@media (min-width: $screen-md) {
			padding-top: 56px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 64px;
		}

		@media (min-width: $screen-xl) {
			padding: 48px 32px;
			padding-top: 104px;
		}
	}

	&__title {
		font-weight: 300;
		font-size: 88px;
		line-height: 1;
		color: $white;

		@media (min-width: $screen-lg) {
			font-size: 56px;
		}
		
		@media (min-width: $screen-xl) {
			font-size: 96px;
		}
	}

	&__unit {
		font-weight: 500;
		font-size: 24px;
		line-height: 34px;

		@media (min-width: $screen-xl) {
			font-weight: 300;
			font-size: 72px;
		}
	}

	&__info {
		font-size: 18px;
		line-height: 30px;
		color: #CFD9E2;

		@media (min-width: $screen-xl) {
			line-height: 36px;
		}
	}

	&__btn-prev,
	&__btn-next {
		display: none;
		top: 31px;

		@media (min-width: $screen-md) {
			display: block;
		}

		@media (min-width: $screen-xl) {
			top: 61px;
		}
	}

	&__btn-prev {
		left: 29px;

		@media (min-width: $screen-xl) {
			left: 44px;
		}
	}

	&__btn-next {
		right: 12px;

		@media (min-width: $screen-xl) {
			right: 25px;
		}
	}
}
