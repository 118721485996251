.section-title {
	margin-top: 0;
	margin-bottom: 24px;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;

	@media (min-width: $screen-xl) {
		margin-bottom: 32px;
		font-size: 36px;
		line-height: 42px;
	}
}
