.about-service {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 24px;

	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__content {
		@include col(xs, 12, 12, 40px);
	}

	&__scheme {
		@include col(lg, 10, 12, 40px);
		@include col-offset(lg, 1, 12);
		@include col(xl, 10, 12, 16px);
		@include col-offset(xl , 1, 12);
		display: none;

		@media (min-width: $screen-lg) {
			display: block;
		}
	}
}

.service-list {
	$self: &;
	margin: 0;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-lg) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 924px;
		margin: 0 auto;
		padding: 0;
	}

	@media (min-width: $screen-xl) {
		width: 1161px;
	}

	@media (min-width: $screen-xxl) {
		width: 1176px;
	}

	&__item {
		position: relative;
		font-size: 14px;
		line-height: 21px;
		padding: 9px 0;
		padding-left: 36px;
		color: $text-color;

		@media (min-width: $screen-lg) {
			width: 213px;
			height: 126px;
			margin: 0;
			margin-right: 24px;
			margin-bottom: 24px;
			padding: 0;
		}

		@media (min-width: $screen-xxl) {
			width: 216px;
			height: 128px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 100%;
			background-color: #C8C8C8;

			@media (min-width: $screen-lg) {
				content: none;
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: 20px;
			left: 0;
			width: 28px;
			height: 1px;
			background-color: #C8C8C8;

			@media (min-width: $screen-lg) {
				content: none;
			}
		}

		&:first-child {
			padding-bottom: 4px;
			padding-left: 0;
			font-size: 18px;
			line-height: 27px;
			color: $color-main;

			&::before {
				content: none;
			}

			&::after {
				content: none;
			}

			@media (min-width: $screen-lg) {
				width: 100%;
				height: 96px;
				margin-right: 0;
			}

			#{$self}__item-wrap {

				@media (min-width: $screen-lg) {
					width: 213px;
					height: 96px;
					margin: 0 auto;
				}

				@media (min-width: $screen-xxl) {
					width: 216px;
				}
			}
		}

		&:last-child {

			&::before {
				top: 0;
				height: 50%;
			}
		}

		&:nth-child(4n+5) {

			@media (min-width: $screen-lg) {
				margin-right: 0;
			}

			@media (min-width: $screen-xl) {
				margin-right: 24px;
			}
		}

		&:nth-child(5n+6) {

			@media (min-width: $screen-xl) {
				margin-right: 0;
			}
		}
	}

	&__item-wrap {

		@media (min-width: $screen-lg) {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 12px 24px;
			text-align: center;
			border: 3px solid #c8c8c8;
			overflow: hidden;
		}
	}
}
