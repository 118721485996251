.scroll-lock {
	height: 100%;
	overflow: hidden;
}

.social {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;

	&__link {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		font-size: 0;
		width: 32px;
		height: 32px;
		text-decoration: none;
		border: 2px solid rgba(189, 189, 189, 0.4);
		border-radius: 50%;

		& + #{$self}__link {
			margin-left: 12px;
		}

		&:hover {
			border: 2px solid rgba(189, 189, 189, 1);

			.icon--social {
				fill: rgba(141, 141, 141, 1);
			}
		}
	}
}

.download {
	$self: &;
	display: inline-block;

	& + #{$self} {
		margin-top: 20px;

		@media (min-width: $screen-xl) {
			margin-top: 26px;
		}
	}

	&--small {

		+ #{$self}--small {
			margin-top: 24px;
		}

		#{$self}__icon {
			margin-top: 0;
			margin-right: 8px;
		}

		#{$self}__link {
			font-size: 18px;
		}

		#{$self}__info {
			padding-left: 40px;
			line-height: 18px;
		}
	}

	&__btn {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;

		&:hover {
			.icon {
				stroke: $grey;
			}
		}
	}

	&__icon {
		flex-shrink: 0;
		margin-top: 2px;
		margin-right: 16px;

		@media (min-width: $screen-xl) {
			margin-right: 8px;
		}

		svg {
			vertical-align: middle;
		}
	}

	&__title-wrap {
		margin-bottom: 10px;

		@media (min-width: $screen-xl) {
			margin-bottom: 6px;
		}
	}

	&__title {
		margin: 0;
		font-size: 16px;
		line-height: 24px;

		&--file {
			text-transform: uppercase;
		}

		@media (min-width: $screen-xl) {
			font-size: 18px;
			line-height: 30px;
		}
	}

	&__info {
		display: block;
		padding-left: 48px;
		font-size: 16px;
		color: $grey-light;

		@media (min-width: $screen-xl) {
			padding-left: 40px;
		}
	}
}

.support-box {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 192px;
	height: 192px;
	background-color: #9BC1E8;
	border: none;

	&--link {

		&:hover {
			background-color: #629FC1;
			border: none;
		}
	}

	&__img-wrap {
		width: 128px;
	}

	&__img {
		width: 100%;
		height: auto;
		vertical-align: middle;
	}
}

.img-scale {
	overflow: hidden;

	&:hover {

		img	{
			transform: scale(1.2);
		}
	}

	img {
		display: inline-block;
		width: 100%;
		transition: all 1.5s ease;
	}
}

.banner-list {
	$self: &;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	&--desktop {
		display: none;

		@media (min-width: $screen-lg) {
			display: flex;
		}
	}

	&--mobile {

		@media (min-width: $screen-lg) {
			display: none;
		}
	}

	&__item {

		+ #{$self}__item {
			margin-top: 16px;

			@media (min-width: $screen-xl) {
				margin-top: 32px;
			}
		}

		&--vertical {

			#{$self}__link {
				max-width: 100%;
				height: 225px;

				@media (min-width: $screen-md) {
					height: 370px;
				}
			}

			#{$self}__img {
				width: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	&__link {
		display: block;
	}

	&__img {
		display: inline-block;
		vertical-align: middle;
	}
}

.news-filter {
	$self: &;
	display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-lg) {
		display: flex;
	}

	&__item {
		margin-right: 20px;
	}

	&__link {
		font-weight: 500;
		font-size: 16px;
		color: $grey;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $color-main;
		}

		&--active {
			color: $color-main;
		}
	}

	&--months {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 14px;

		#{$self}__item {
			margin-bottom: 10px;
		}

	}
}

.news-empty {
	display: none;

	&__text {
		font-size: 20px;
		line-height: 28px;
	}
}

.news-video {

	&__wrapper {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 25px;
		height: 0;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
