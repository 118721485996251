.payment {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 24px;

	@media (min-width: $screen-md) {
		padding-top: 48px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 72px;
	}
	
	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}
	
	&--item {
		padding-top: 0;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__list-wrap {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 10, 12, 40px);
		@include col(xl, 10, 12, 16px);
	}

	&__text-disclaimer {
		margin-bottom: 0;
	}

	&__return	{
		@include col(xs, 12, 12, 40px);
		@include col(xl, 12, 12, 16px);

		@media (min-width: $screen-lg) {
			margin-bottom: 24px;
			padding-top: 24px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 48px;
			padding-top: 40px;
		}
	}

	&__item-wrap {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
	}
}
