.about-facts {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 32px;

	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}
	&__text {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
	}

	&__logo {
		@include col(lg, 4, 12, 40px);
		@include col(xl, 4, 12, 16px);
		display: none;

		@media (min-width: $screen-lg) {
			display: flex;
		}
	}

	&__logo-wrap {
		width: 224px;
		margin: 0 auto;
	}

	&__logo-img {
		width: 100%;
	}
}
