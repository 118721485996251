.page-footer {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
		padding-top: 32px;
		padding-bottom: 32px;

		@media (min-width: $screen-xl) {
			padding-top: 88px;
			padding-bottom: 104px;
		}
	}

	&__nav {
		@include col(lg, 6, 12, 40px);
		@include col(xl, 6, 12, 16px);
		display: none;

		@media (min-width: $screen-lg) {
			display: flex;
		}
	}

	&__nav-wrap {
		@include row(0, 0, 0, 0, 0, 0);
		width: 100%;
	}

	&__form {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(lg, 4, 12, 40px);
		@include col(xl, 4, 12, 16px);
	}

	&__subscribe-form {
		max-width: 340px;

		@media (min-width: $screen-lg) {
			max-width: none;
			margin-bottom: 24px;
		}
	}

	&__form-social {
		display: none;

		@media (min-width: $screen-lg) {
			display: block;
		}

		@media (min-width: $screen-xl) {
			display: none;
		}
	}

	&__social {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(lg, 3, 12, 40px);
		@include col(xl, 2, 12, 16px);
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		padding-top: 32px;
		padding-bottom: 24px;

		@media (min-width: $screen-md) {
			justify-content: flex-end;
			align-items: flex-end;
			padding-top: 0;
			padding-bottom: 0;
		}

		@media (min-width: $screen-lg) {
			display: none;
		}

		@media (min-width: $screen-xl) {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
		}
	}

	&__copyright {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(lg, 5, 12, 40px);
		@include col(xl, 4, 12, 16px);
		font-size: 16px;
		line-height: 25px;

		@media (min-width: $screen-md) {
			padding-top: 40px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 64px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 80px;
		}
	}

	&__developer {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(lg, 5, 12, 40px);
		@include col(xl, 3, 12, 16px);
		@include col-offset(lg, 2, 12);
		@include col-offset(xl, 5, 12);
		padding-top: 24px;
		font-size: 16px;
		line-height: 25px;

		@media (min-width: $screen-md) {
			padding-top: 40px;
			text-align: right;
		}

		@media (min-width: $screen-lg) {
			padding-top: 64px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 80px;
		}
	}

}

.footer-nav {
	$self: &;
	@include col(lg, 6, 12, 0);
	@include col(xl, 6, 12, 0);
	margin: 0;
	padding: 0;
	list-style: none;

	& + #{$self} {
		padding-left: 8px;
	}

	&__link {
		font-size: 16px;
		line-height: 32px;
		border: none;

		@media (min-width: $screen-xl) {
			font-size: 18px;
			line-height: 36px;
		}

		&:hover,
		&:focus {
			color: $color-main;
			text-decoration: none;
			border-bottom: none;
		}

		&--active {
			color: $color-main;
		}
	}
}
