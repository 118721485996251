.search-link {
	$self: &;

	&__icon-wrap {
		line-height: 0;
	}

	&__icon {
		vertical-align: top;

		path {
			fill: $color-main;
			transition: fill $transition-time;
		}
	}

	&:hover {

		#{$self}__icon {

			path {
				fill: $color-main--hover;
			}
		}
	}
}
