.documents {
	$self: &;
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 24px;

	@media (min-width: $screen-md) {
		padding-top: 48px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 72px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__content {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
	}

	&__title {
		margin-top: 0;
	}

	&__block {

		& + #{$self}__block {
			margin-top: 40px;
		}
	}
}
