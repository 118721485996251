.payment-list {
	$self: &;
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin-bottom: 48px;
		
		@media (min-width: $screen-md) {
			flex-direction: row;
			align-items: flex-start;
		}
		
		@media (min-width: $screen-lg) {
			flex-direction: row;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 64px;
		}
	}

	&__img-wrap {
		flex-shrink: 0;
		max-width: 327px;
		margin-bottom: 16px;

		@media (min-width: $screen-sm) {
			width: 327px;
			max-width: none;
		}

		@media (min-width: $screen-md) {
			margin-right: 24px;
			text-align: center;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 0;
		}

		@media (min-width: $screen-xl) {
			margin-right: 48px;
		}
	}

	&__link,
	&__title {
		font-size: 24px;
		line-height: 30px;

		@media (min-width: $screen-xl) {
			font-size: 36px;
			line-height: 42px;
		}
	}

	&__value {
		display: block;
		margin-top: 8px;
		font-size: 18px;
		line-height: 30px;
		color: $color-main;
		transition: all $transition-time;

		@media (min-width: $screen-xl) {
			font-size: 24px;
			line-height: 42px;
		}
	}

	&__note {
		font-size: 18px;
		line-height: 30px;
		color: $grey;
		
		@media (min-width: $screen-xl) {
			font-size: 24px;
			line-height: 42px;
		}
	}

	&__value {

		& + #{$self}__link-value {
			margin-top: 2px;
		}
	}
}
