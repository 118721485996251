.props-list {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 0 -8px 0;
	padding: 0;
	list-style: none;

	&__item {
		margin-right: 48px;
		margin-bottom: 8px;
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;

		@media (min-width: $screen-xl) {
			font-size: 20px;
			line-height: 28px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__item-tip {
		display: block;
		font-size: 12px;
		line-height: 1;
	}

	&--card {
		flex-direction: column;

		#{$self}__item {
			margin-bottom: 32px;
			font-weight: 300;
			font-size: 48px;
			line-height: 48px;

			@media (min-width: $screen-xl) {
				margin-bottom: 64px;
				font-size: 64px;
				line-height: 1;
			}
		}

		#{$self}__item-tip {
			font-size: 16px;
		}
	}
}
