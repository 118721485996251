.card-item {

	&__row {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__col-header {
		@include col(xs, 12, 12, 40px);
		@include col(md, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
	}

	&__col-main {
		@include col(xs, 12, 12, 40px);
		@include col(md, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
	}


	&__col-info {
		@include col(xs, 12, 12, 40px);
		@include col(md, 4, 12, 40px);
		@include col(xl, 3, 12, 16px);
		@include col-offset(xl, 1, 12);

		@media (max-width: $screen-md - 1) {
			display: none;
		}
	}

	&__props {

		&--mobile {
			margin-bottom: 24px;

			@media (min-width: $screen-md) {
				display: none;
			}
		}

	}
}
