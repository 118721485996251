.not-found {
	@include col(xs, 12, 12, 40px);
	@include col(lg, 6, 12, 40px);
	@include col(xl, 6, 12, 16px);
	padding-top: 40px;

	@media (min-width: $screen-xl) {
		padding-top: 88px;
	}

	&__title {
		margin-top: 0;
		margin-bottom: 16px;
		font-weight: 300;
		font-size: 144px;
		line-height: 288px;
		color: $color-main;
		
		@media (min-width: $screen-xl) {
			font-size: 288px;
			line-height: 288px;
		}
	}

	&__text {
		margin-bottom: 12px;
		font-size: 24px;
		line-height: 30px;
		
		@media (min-width: $screen-xl) {
			font-size: 36px;
			line-height: 48px;
		}
	}
}
