.about-advantages {
	$self: &;
	background-color: #F0F0F0;

	&__inner {
		@include container(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
		padding-top: 32px;
		padding-bottom: 32px;
		
		@media (min-width: $screen-xl) {
			padding-top: 64px;
			padding-bottom: 72px;
		}
	}

	&__item {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(lg, 3, 12, 40px);
		@include col(xl, 3, 12, 16px);

		& + #{$self}__item {
			margin-top: 16px;
		}

		&:nth-child(1) {
			@include col(lg, 3, 12, 40px);
			@include col(xl, 3, 12, 16px);
		}

		&:nth-child(2) {
			@include col(lg, 4, 12, 40px);
			@include col(xl, 4, 12, 16px);
			@include col-offset(lg, 1, 12);
			@include col-offset(xl, 1, 12);
		}

		&:nth-child(3) {
			@include col(lg, 4, 12, 40px);
			@include col(xl, 4, 12, 16px);
		}

		@media (min-width: $screen-md) {
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}

			& + #{$self}__item {
				margin-top: 0;
			}
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 32px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 40px;
		}
	}

	&__value {
		font-weight: 300;
		font-size: 48px;
		line-height: 1;
		color: $color-main;

		@media (min-width: $screen-lg) {
			&--big {
				font-size: 72px;
			}
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 4px;
			font-size: 72px;

			&--big {
				font-size: 96px;
			}
		}
	}

	&__unit {
		font-weight: 500;
		font-size: 24px;

		@media (min-width: $screen-xl) {
			font-size: 36px;
		}
	}

	&__description {
		font-size: 16px;
		line-height: 24px;
		color: $grey;

		@media (min-width: $screen-xl) {
			font-size: 18px;
		}
	}
}
