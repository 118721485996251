.about-history {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 40px;

	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__history-list {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 12, 12, 40px);
		@include col(xl, 11, 12, 16px);
	}
}

.history-list {
	$self: &;
	margin: 0;
	list-style: none;

	&__item {
		@include row(0, 0, 0, 0, 0, 0);

		& + #{$self}__item {
			margin-top: 28px;
		}

		@media (min-width: $screen-lg) {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 7px;
				width: 2px;
				height: 100%;
				background-color: rgba(228, 43, 37, 0.25);
			}

			&::after {
				content: "";
				position: absolute;
				top: 28px;
				left: 0;
				width: 16px;
				height: 16px;
				background-color: $white;
				border: 2px solid $color-main;
				border-radius: 50%;
				transform: translateY(-50%);
			}

			&:first-child {
				&::before {
					top: 28px;
					height: calc(100% - 28px);
				}
			}

			&:first-child {
				&::after {
					top: 28px;
				}
			}

			&:last-child {
				&::before {
					background: linear-gradient(180deg, #E42B25 0%, rgba(228, 43, 37, 0) 100%);
					opacity: 0.25;
				}
			}

			& + #{$self}__item {
				margin-top: 0;
			}
		}
	}

	&__date {
		@include col(xs, 12, 12, 0);
		@include col(lg, 4, 12, 40px);
		@include col(xl, 4, 12, 16px);
		margin-bottom: 8px;
		font-weight: 300;
		font-size: 48px;
		line-height: 1;
		color: $color-main;
		white-space: nowrap;

		@media (min-width: $screen-lg) {
			padding-left: 77px;
		}
	}

	&__text {
		@include col(xs, 12, 12, 0);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 7, 12, 16px);

		@media (min-width: $screen-lg) {
			margin-bottom: 28px;
			padding-left: 32px;
		}
	}
}
