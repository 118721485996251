@at-root {

  @-ms-viewport {
    width: device-width;
  }
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v13-latin_cyrillic-regular.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-v13-latin_cyrillic-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v13-latin_cyrillic-italic.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-v13-latin_cyrillic-italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v13-latin_cyrillic-500.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-v13-latin_cyrillic-500.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v13-latin_cyrillic-700.woff2') format('woff2'),
	url('../fonts/montserrat/montserrat-v13-latin_cyrillic-700.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;

  &__inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__content {
    flex-grow: 1;
  }

	&__main {
		padding-bottom: 80px;

		@media (min-width: $screen-xl) {
			padding-bottom: 128px;
		}

	}

	&__container {
		@include container(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__row {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

  &__footer-wrapper {
    flex-shrink: 0;
		background-color: #f0f0f0;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
/*  word-break: break-word;*/
  font-family: $font-family;
	font-weight: 500;
  font-size: $font-size;
  line-height: $line-height;
  text-align: left;
  color: $text-color;
  background-color: $body-bg;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[tabindex='-1']:focus {
  outline: none !important;
}

*:focus {
	outline: none;
}

[role='button'] {
  cursor: pointer;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

input,
textarea {
	-webkit-appearance: none;
	border-radius: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
	vertical-align: middle;
}

a {
  color: $link-color;
	text-decoration: none;
  background-color: transparent;
	transition: all $transition-time;

	&:not([class]) {
		border-bottom: 1px solid rgba(50, 50, 50, 0.25);

		&:hover,
		&:focus {
			border-bottom: 1px solid transparent;
		}
	}

  &[href^="tel:"] {

    @media (min-width: $screen-xl) {
      white-space: nowrap;
      pointer-events: none;
      text-decoration: inherit;
      color: inherit;
    }
  }

	&[href^="tel:"],
	&[href^="mailto:"] {
		border-bottom: none;
		white-space: nowrap;

		&:hover,
		&:focus {
			border-bottom: none;
		}
	}
}


.link {
	border-bottom: 1px solid rgba(50, 50, 50, 0.25);

	&:hover,
	&:focus {
		border-bottom: 1px solid transparent;
	}

	&--theme_red {
		color: rgba(228, 43, 37, 1);
		border-bottom: none;

		&:hover,
		&:focus {
			color: rgba(228, 43, 37, 0.7);
			border-bottom: none;
		}
	}
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: $typo-margin-vertical * 2;
  margin-bottom: $typo-margin-vertical * 2;
  border: 0;
  border-top: 1px solid $border-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: $font-family;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
	font-weight: 300;
	font-size: 24px;
	line-height: 34px;
	color: $text-color;

	@media (min-width: $screen-lg) {
		font-size: 72px;
		line-height: 72px;
	}
}

h2 {
	margin-bottom: 24px;
	font-size: 24px;
	line-height: 30px;

	@media (min-width: $screen-xl) {
		margin-bottom: 32px;
		font-size: 36px;
		line-height: 42px;
	}
}

h3 {
	margin-top: 24px;
	margin-bottom: 8px;
	font-size: 20px;
	line-height: 28px;

	@media (min-width: $screen-xl) {
		margin-top: 32px;
		font-size: 24px;
		line-height: 34px;
	}
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: $typo-margin-vertical;
}

p {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	@media (min-width: $screen-xl) {
		font-size: 18px;
		line-height: 30px;
	}
}

.footnote {
	font-size: 14px;
	line-height: 30px;
	color: $grey-light;
	margin-bottom: 12px;

	&:hover {
		color: $grey;
	}
}

.text-disclaimer,
.image-note {
	margin-bottom: 12px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: $grey;
}

.image-note {
	margin-bottom: 24px;

	@media (min-width: $screen-xl) {
		margin-bottom: 32px;
	}
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: $typo-margin-vertical;
  padding-left: 1.5em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

li {
  line-height: inherit;
}

// list with counter

.counter-list,
.counter-list ol {
	padding-left: 0;
	list-style: none;
	counter-reset: li;
}

.counter-list {
	margin-bottom: 0;
	padding-left: 4em;
}

.counter-list ol {
	margin-top: 12px;
}

.counter-list ol li + li {
	margin-top: 12px;
}

.counter-list > li {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;

	@media (min-width: $screen-xl) {
		font-size: 18px;
		line-height: 30px;
	}
}

.counter-list > li + li {
	margin-top: 36px;

	@media (min-width: $screen-xl) {
		margin-top: 48px;
	}
}

.counter-list ol > li {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	@media (min-width: $screen-xl) {
		font-size: 18px;
		line-height: 30px;
	}
}

.counter-list li:before {
	display: inline-block;
	width: 3.5em;
	margin-left: -3.5em;
	counter-increment: li;
	content: counters(li,".") ". ";
}

small {
  font-size: $font-size-sm;
}

sub,
sup {
  font-size: $font-size-sm;
}

b,
strong {
  font-weight: bolder;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

code,
kbd,
pre {
  font-family: $font-family--monospace;
}

code {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: rgba(#000, 0.08);
  border-radius: $border-radius;
}

kbd {
  padding: 0.06em 0.3em;
  color: $text-color;
  border-radius: $border-radius;

  kbd {
    padding: 0;
  }
}

pre {
  display: block;
  width: 100%;
  margin-bottom: $typo-margin-vertical;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);

  code {
    background: transparent;
  }
}

.visuallyhidden {
	position: absolute;
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

