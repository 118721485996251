.main-info {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);

	padding-top: 16px;

	@media (min-width: $screen-lg) {
		padding-top: 32px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 64px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__news {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 6, 12, 40px);
		@include col(xl, 6, 12, 16px);
		padding-top: 56px;

		@media (min-width: $screen-lg) {
			padding-top: 0;
		}
	}

	&__news-header {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin-bottom: 48px;

		@media (min-width: $screen-sm) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__news-title {
		margin: 0;
		margin-bottom: 4px;
		padding: 0;
		font-size: 36px;
		line-height: 42px;

		@media (min-width: $screen-sm) {
			margin: 0;
		}

		@media (min-width: $screen-xl) {
			line-height: 36px;
		}
	}

	&__news-link {
		font-size: 18px;
		line-height: 24px;
	}

	&__tiles {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 5, 12, 40px);
		@include col(xl, 5, 12, 16px);
		@include col-offset(lg, 1, 12);
		@include col-offset(xl, 1, 12);
		order: -1;

		@media (min-width: $screen-lg) {
			order: 1;
		}
	}

	&__tiles-wrap {
		position: relative;
		z-index: 10;
		width: 100%;

		@media (min-width: $screen-lg) {
			margin-top: -128px;
		}
	}
}
