.card-list {
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		padding-top: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid #E0E0E0;

		@media (min-width: $screen-xl) {
			padding-top: 48px;
			padding-bottom: 48px;
		}

		&:first-child {
			padding-top: 0;
		}
	}

	&__item-inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__img-wrap {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(xl, 6, 12, 16px);

		@media (max-width: $screen-md - 1) {
			margin-bottom: 12px;
		}
	}

	&__img-link {
		display: block;
		overflow: hidden;
	}

	&__img {}


	&__info {
		@include col(xs, 12, 12, 40px);
		@include col(md, 6, 12, 40px);
		@include col(xl, 5, 12, 16px);
	}

	&__date {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 24px;
		color: $grey-light;
	}

	&__title {
		margin-top: 0;
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;

		@media (min-width: $screen-xl) {
			margin-bottom: 16px;
			font-size: 24px;
			line-height: 34px;
		}
	}

	&__props {
		margin-bottom: 8px;

		@media (min-width: $screen-xl) {
			margin-bottom: 16px;
		}
	}

	&__text {
		margin-bottom: 0;
		font-size: 16px;
		line-height: 24px;
		color: $grey;

		@media (min-width: $screen-xl) {
			font-size: 18px;
			line-height: 30px;
		}
	}
}
