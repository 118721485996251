.press-form {
	display: none;
	margin-top: 40px;

	@media (min-width: $screen-xl) {
		margin-top: 64px;
	}

	&__title {
		margin-top: 0;
	}

	&__wrap {
		width: 100%;
		padding: 32px 24px;
		background-color: #F0F0F0;

		@media (min-width: $screen-lg) {
			max-width: 768px;
		}

		@media (min-width: $screen-xl) {
			padding: 48px 40px;
		}
	}
}
