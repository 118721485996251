.subscribe-form {
	$self: &;

	&__label {
		display: block;
		margin-bottom: 8px;
		font-size: 14px;
		line-height: 24px;
	}

	&__input-wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: 100%;
	}

	&__input {
		width: 100%;
		margin: 0;
		padding: 0;
		padding-left: 14px;
		font-size: 14px;
		line-height: 36px;
		background-color: transparent;
		border: 2px solid #c8c8c8;
		border-right: none;

		&:focus {
			border-color: $grey-light;
		}

		&.invalid {
			border-color: $color-danger;
		}

		&.success + #{$self}__btn {
			background-color: $color-success;

			&:hover {
				background-color: $color-success--hover;
			}

			#{$self}__icon {
				display: none;
			}

			#{$self}__icon--success {
				display: inline;
			}
		}
	}

	&__btn {
		flex-shrink: 0;
		width: 51px;
		height: 40px;
		font-size: 0;
		background-color: $color-main;
		border: none;

		&:hover {
			background-color: $color-main--hover;
		}
	}

	&__icon {

		&--success {
			display: none;
		}
	}

	&__success {
		font-size: 14px;
		padding-top: 12px;
	}
}

.form-wrap {
	$self: &;

	+ #{$self} {
		margin-top: 28px;
	}
}

.form-label {
	display: block;
	margin-bottom: 6px;
	font-size: 18px;
	line-height: 30px;

	@media (min-width: $screen-xl) {
		font-size: 20px;
		line-height: 28px;
	}
}

.form-radio-label {
	position: relative;
	display: inline-block;
	margin-bottom: 12px;
	padding-left: 33px;
	font-size: 16px;
	line-height: 28px;

	@media (min-width: $screen-xl) {
		font-size: 18px;
	}
}

.form-input {
	padding: 12px 16px;
	font-family: $font-family;
	font-size: 20px;
	line-height: 28px;
	background-color: transparent;
	border: 2px solid $border-color;
	box-shadow: none;

	&::placeholder {
		font-family: $font-family;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		color: $grey-light;
	}

	&.invalid {
		border-color: $color-danger;
	}

	&:focus {
		border-color: $grey-light;
	}
}

.form-radio {
	@include visuallyhidden();

	& + .form-radio-label {
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 0;
			width: 24px;
			height: 24px;
			border: 2px solid $border-color;
			border-radius: 50%;
		}
	}

	&:checked + .form-radio-label {

		&::after {
			content: "";
			position: absolute;
			top: 14px;
			left: 8px;
			width: 8px;
			height: 8px;
			background-color: $blue;
			border-radius: 50%;
			transform: translateY(-50%);
		}
	}

	&:focus + .form-radio-label::before {
		border-color: $grey-light;
	}

	&.invalid + .form-radio-label::before {
		border-color: $color-danger;
	}
}

.form-textarea {
	display: block;
	padding: 16px 16px;
	font-size: 20px;
	line-height: 28px;
	background-color: transparent;
	border: 2px solid $border-color;
	resize: none;

	&:focus {
		border-color: $grey-light;
	}
}

.form-set-wrap {
	$self: &;

	+ #{$self} {

		@media (max-width: $screen-md - 1) {
			margin-top: 28px;
		}

		@media (min-width: $screen-md) {
			margin-left: 32px;
		}
	}
}

.form-set {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 28px;
	margin-bottom: 28px;

	@media (min-width: $screen-md) {
		flex-direction: row;
	}
}

.form-error {
	display: none;
	color: $red;
}

.form-error > .invalid {
	display: block;
	padding-top: 8px;
}

.form-error > .invalid:empty {
	padding-top: 0;
}

.invalid + .form-error {
	display: block;
}

.selectric-form-select {
	background-color: inherit;

	.selectric {
		background: none;
		background-color: inherit;
		border: 2px solid $border-color;

		.label {
			height: 52px;
			margin-right: 46px;
			margin-left: 14px;
			padding: 12px 0;
			font-size: 20px;
			line-height: 28px;
			color: $text-color;
		}

		.button {
			width: 46px;
			height: 52px;
			color: inherit;
			background-color: inherit;

			&::after {
				//@include triangle-arrow(11px, 2px, $border-color, down, 0, -50%);
				//top: 4px;
				top: 50%;
				left: 50%;
				width: 11px;
				height: 11px;
				margin: 0;
				border: none;
				border-right: 2px solid $border-color;
				border-bottom: 2px solid $border-color;
				transform-origin: top left;
				transform: rotate(45deg) translate(-50%, -50%);
			}
		}
	}

	.selectric-items {
		background: none;
		background-color: inherit;
		border: 2px solid $grey-light;
		box-shadow: none;

		ul {
			padding: 5px 0;
		}

		li {
			padding: 8px 0;
			padding-left: 14px;
			font-size: 16px;
			line-height: 22px;
			color: $grey;

			&:hover {
				color: $color-main;
				background-color: transparent;
			}

			&.highlighted {
				color: $color-main;
				background-color: transparent;
			}

			&.selected {
				color: $color-main;
				background-color: transparent;
			}

			&:first-child.disabled {
				display: none;
			}
		}
	}

	&.selectric-open {

		.selectric {
			border-color: $grey-light;

			.button {

				&::after {
					top: 50%;
					left: 50%;
					width: 11px;
					height: 11px;
					margin: 0;
					border: none;
					border-right: 2px solid $border-color;
					border-bottom: 2px solid $border-color;
					transform-origin: top left;
					transform: rotate(-135deg) translate(-50%, -50%);
				}
			}
		}
	}

	&.selectric-below {

		.selectric-items {
			border-top: none;
		}
	}

	&.selectric-above {

		.selectric-items {
			border-bottom: none;
		}
	}

	&.invalid {

		.selectric {
			border-color: $color-danger;

			.button {

				&::after {
					border-color: transparent $color-danger $color-danger transparent;
				}
			}
		}
	}

	&--filter {

		.selectric {

			.label {
				height: 44px;
				padding: 8px 0;
				font-size: 18px;
				color: $color-main;
			}

			.button {
				height: 44px;
			}
		}
	}
}

// fix autocomplete

@-webkit-keyframes autofill {
	to {
		background: transparent;
	}
}

input:-webkit-autofill {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}
