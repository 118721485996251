.order-form {
	$self: &;
	padding-top: 32px;
	padding-bottom: 40px;
	background-color: #f0f0f0;

	@media (min-width: $screen-lg) {
		padding-left: 40px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 46px;
		padding-bottom: 64px;
	}

	&__form-group {
		margin-bottom: 48px;
	}

	&__name {
		width: 100%;
		background-color: #f0f0f0;

		@media (min-width: $screen-sm) {
			width: 400px;
		}
	}

	&__phone {
		width: 100%;
		background-color: #f0f0f0;

		@media (min-width: $screen-sm) {
			width: 256px;
		}
	}

	&__radio-group {
		margin-top: 8px;

		@media (min-width: $screen-xl) {
			margin-top: 12px;
		}
	}

	&__station {
		width: 100%;
		background-color: #f0f0f0;

		@media (min-width: $screen-sm) {
			width: 400px;
		}
	}

	&__arrival {
		width: 160px;
		background-color: #f0f0f0;
	}

	&__date {
		width: 160px;
		background-color: #f0f0f0;
		cursor: pointer;
	}

	&__comments {
		width: 100%;
		height: 192px;
		background-color: #f0f0f0;

		@media (min-width: $screen-sm) {
			width: 460px;
		}
	}

	&__entrance {
		width: 100%;
		max-width: 160px;
		background-color: #f0f0f0;
	}

	&__btn {
		width: 242px;
	}

	&__success-title {
		margin-bottom: 12px;
		font-size: 24px;
		line-height: 34px;

		@media (min-width: $screen-xl) {
			margin-bottom: 20px;
			font-size: 36px;
			line-height: 42px;
		}
	}

	&__input {
		width: 100%;
	}

	&--press {
		padding: 0;

		#{$self}__input {

			@media (min-width: $screen-md) {
				max-width: 544px;
			}

			&--short {

				@media (min-width: $screen-md) {
					max-width: 344px;
				}
			}
		}
	}
}
