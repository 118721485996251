.svg-sprite {
	display: none;
}

.icon {
	transition: all $transition-time;

	&--social {
		fill: rgba(189, 189, 189, 1);

		&:hover {
			fill: rgba(141, 141, 141, 1);
		}
	}

	&--form-button {
		stroke: $white;
		fill: none;
	}

	&--more,
	&--back,
	&--download {
		fill: none;
		stroke: $grey-light;

		&:hover {
			stroke: $grey;
		}
	}
}
