.page-header {
	$self: &;
	@include container(40px, 40px, 40px, 40px, 16px, 16px);

	&--submenu {

		#{$self}__logo {

			@media (min-width: $screen-lg) {
				margin-bottom: 5px;
			}
		}

		#{$self}__content {
			padding-bottom: 8px;

			@media (min-width: $screen-lg) {
				padding-bottom: 14px;
			}
		}
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__content {
		@include col(xs, 12, 12, 40px);
		@include col(xl, 12, 12, 16px);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		//align-items: center;
		position: relative;
		padding-top: 8px;
		padding-bottom: 17px;

		@media (min-width: $screen-lg) {
			flex-wrap: nowrap;
		}

		@media (min-width: $screen-xl) {
			padding-bottom: 8px;
		}
	}

	&__logo {
		flex-shrink: 0;
		width: 62px;
		height: 53px;
		margin-right: 20px;

		&:hover {
			opacity: 0.8;
		}

		@media (min-width: $screen-xl) {
			width: 93px;
			height: 80px;
			margin-right: 38px;
		}
	}

	&__nav {

		@media (min-width: $screen-lg) {
			width: 100%;
			padding-top: 4px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 18px;
		}

		@media (min-width: $screen-xxl) {
			padding-left: 100px;
		}
	}

	&__search {
		align-self: center;
		margin-right: 20px;
		margin-left: auto;
		padding: 10px 0;

		@media (min-width: $screen-lg) {
			margin-right: 0;
			margin-left: 20px;
			padding-bottom: 12px;
		}

		@media (min-width: $screen-xl) {
			padding-bottom: 12px;
		}

		@media (min-width: $screen-xxl) {
			margin-left: 30px;
		}
	}
}

.main-nav {
	$self: &;
	display: none;
	background-color: $white;

	@media (min-width: $screen-lg) {
		display: block;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-lg) {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}

	&--open {
		display: block;
		position: absolute;
		top: 78px;
		left: 0;
		z-index: 50;
		width: 100%;
		padding: 60px 0;
		text-align: center;
		overflow-x: hidden;
		overflow-y: auto;
		height: 100vh;

		@media (min-width: $screen-md) {
			height: 100vh;
		}

		#{$self}__list {
			padding-bottom: 60px;
		}
	}

	&__item {
		padding: 10px 0;

		@media (min-width: $screen-lg) {
			padding-bottom: 8px;
		}

		@media (min-width: $screen-xl) {
			padding-bottom: 12px;
		}
	}

	&__link {
		font-weight: 500;
		font-size: 24px;
		color: $text-color;
		text-decoration: none;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $color-main;
			text-decoration: none;
		}

		&--active {
			color: $color-main;
		}

		@media (min-width: $screen-lg) {
			font-size: 14px;
		}

		@media (min-width: $screen-xl) {
			font-size: 16px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 18px;
		}
	}

	&__btn {
		padding-top: 18px;
		padding-bottom: 13px;
		padding-left: 13px;

		@media (min-width: $screen-lg) {
			display: none;
		}
	}
}

.sub-nav {
	display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-lg) {
		display: flex;
	}

	&__item {
		margin-right: 26px;
	}

	&__link {
		font-weight: 500;
		font-size: 16px;
		color: $grey;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $color-main;
		}

		&--active {
			color: $color-main;
		}
	}
}

.select-nav {
	width: 100%;
	margin-top: 17px;
	margin-bottom: 8px;

	&__select {
		max-width: 335px;
		background-color: $white;
	}

	@media (min-width: $screen-lg) {
		display: none;
	}
}
