.back {
	padding-bottom: 24px;

	@media (min-width: $screen-lg) {
		padding-top: 24px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 32px;
		padding-bottom: 80px;
	}
}
