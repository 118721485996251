.about-rules {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 32px;

	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__disclaimer {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 5, 12, 16px);
		@include col(xxl, 6, 12, 16px);
		margin-bottom: 20px;

		@media (min-width: $screen-xl) {
			margin-bottom: 36px;
		}
	}

	&__content {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
	}
}
