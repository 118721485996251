.support {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);
	padding-top: 24px;

	@media (min-width: $screen-lg) {
		padding-top: 72px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 96px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__title {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
		margin: 0;
		margin-bottom: 24px;

		@media (min-width: $screen-xl) {
			margin-bottom: 30px;
		}
	}

	&__form {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
		background-color: #f0f0f0;
		
		@media (min-width: $screen-lg) {
			padding-right: 24px;
			background-color: $body-bg;
		}
	}

	&__img-wrap {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 4, 12, 40px);
		@include col(xl, 4, 12, 16px);
		display: none;

		@media (min-width: $screen-lg) {
			display: block;
			padding-left: 24px;
		}
	}

	&__description {
		@include col(xs, 12, 12, 40px);
		@include col(lg, 8, 12, 40px);
		@include col(xl, 8, 12, 16px);
		@include col(xxl, 7, 12, 16px);
		padding-top: 24px;

		@media (min-width: $screen-xl) {
			padding-top: 44px;
		}
	}

}
