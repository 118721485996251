.news {
	@include container(40px, 40px, 40px, 40px, 16px, 16px);

	@media (min-width: $screen-lg) {
		padding-top: 40px;
	}
	
	@media (min-width: $screen-xl) {
		padding-top: 56px;
	}

	&__inner {
		@include row(40px, 40px, 40px, 40px, 16px, 16px);
	}

	&__list-wrap {
		@include col(xs, 12, 12, 40px);
		@include col(xl, 12, 12, 16px);
	}

	&__btn-wrap {
		margin-top: 38px;
		text-align: center;

		@media (min-width: $screen-xl) {
			margin-top: 16px;
		}
	}
}
